/**
 * Adds two numbers.
 * @customfunction 
 * @param first First number.
 * @param second Second number.
 * @returns The sum of the two numbers.
 */

function add(first, second) {
  return first + second;
}
CustomFunctions.associate("ADD", add);